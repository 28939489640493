<template>
  <div>
    <v-row>
      <v-col
        :cols="12 / cameras.length"
        v-for="(camera, i) in cameras"
        :key="i"
      >
        <div class="d-flex flex-column align-center">
          <v-card :loading="!cameraImages[i]">
            <v-responsive :aspect-ratio="4 / 3" min-width="200" max-width="800">
              <template v-if="cameraImages[i]">
                  <v-overlay absolute v-if="capturing">
                    <v-progress-circular indeterminate />
                  </v-overlay>
                <div>
                  <canvas
                    :id="`canvas-${camera}`"
                    class="rounded"
                    style="vertical-align: bottom"
                  />
                </div>
              </template>
              <v-card-text v-else class="text-center">{{
                $t("loading")
              }}</v-card-text>
            </v-responsive>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex flex-column align-center">
      <v-toolbar dark color="primary" class="mt-3" rounded dense>
        <v-toolbar-items>
          <v-btn
            icon
            dark
            :loading="capturing"
            :disabled="
              !cameraImages.find(img => !!img) ||
                !!capturedImages.find(img => !!img)
            "
            @click="getCameraImage()"
          >
            <v-icon>mdi-camera</v-icon>
          </v-btn>
          <v-btn
            icon
            dark
            :loading="capturing"
            :disabled="!capturedImages.find(img => !!img)"
            @click="save"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
          <v-btn
            icon
            dark
            :loading="capturing"
            :disabled="!capturedImages.find(img => !!img)"
            @click="capturedImages = cameras.map(() => null)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
    </div>
  </div>
</template>
<script>
import { Fisheye, } from "@/utils/fisheye";

const delay = ms => new Promise(res => setTimeout(res, ms));

export default {
  name: "CameraController",
  props: {
    cameras: {
      required: true,
      validator(value) {
        // The value must match one of these strings
        return !value.find(v => !["feet", "heel1", "heel2"].includes(v));
      }
    },
    show: { type: Boolean, default: false }
  },
  data() {
    return {
      cameraImages: this.cameras.map(() => null),
      capturedImages: this.cameras.map(() => null),
      previewLoading: this.cameras.map(() => false),
      capturing: false,
      activated: false
    };
  },
  mounted() {
    this.activated = true;
    const self = this;
    var fun = async () => {
      if (!self.activated)
      {
        //no setTimeout
        return;
      }
      console.log("self.capturing:", self.capturing);
      if (
        !self.show ||
        self.capturing ||
        self.capturedImages.find(img => !!img)
      ) {
        window.setTimeout(fun, 1000);
        return;
      }
      
      await self.getCameraImagePreview();
      
      window.setTimeout(fun, 100);
    };
    
    this.interval = window.setTimeout(fun, 1000);
  },
  methods: {
    unDistort(canvas, image, camera) {
      var lens;
      var fov;
      
      fov = {
        x : 1.0,
        y : 1.0
      };
      
      if (camera == "feet")
      {
        lens = this.$store.state.user.foot_lens_data;
        
        /*
        lens = {
          a : 1.0,
          b : 1.0,
          Fx : 0.20,
          Fy : 0.20,
          F2x : -0.1,
          F2y : -0.1,
          F3x : 0.0,
          F3y : 0.0,
          scale : 1.0,
          rotation :  0.0,
          rotationX : 0.0,
          rotationY : 0.0,
          shiftX: 0.0,
          shiftY: 0.0,
          
          wx1 : 0.00,
          wx2 : 1.00,
          
          wy1 : 0.002,
          wy2 : 0.998,
          
          bx1 : -0.86,
          bx2 : 0.83,
          
          by1 : -1.0,
          by2 : 1.0,
        };
        */
      }

      if (camera == "heel1")
      {
        lens = this.$store.state.user.heel1_lens_data;
      }

      if (camera == "heel2")
      {
        lens = this.$store.state.user.heel2_lens_data;
      }

      console.log(lens);

      Fisheye(canvas, image, lens, fov);
    },
    async getCameraImagePreview() {
      console.log("=================================", (Date.now()+0.0)/1000.0);
      const images = this.cameras.map(() => null);
      
      for (let i = 0; i < this.cameras.length; i++) {
        try {
          if (!this.previewLoading[i]) {
            this.previewLoading[i] = true;
            images[i] = await this.$store.dispatch(
              "getBoxPreview",
              this.cameras[i]
            );
            console.count(`${this.cameras[i]} req`);
          } else {
            console.count(`${this.cameras[i]} block`);
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.previewLoading[i] = false;
        }
      }
      
      this.cameraImages = images.map((img, i) => img || this.cameraImages[i]);

      for (let i = 0; i < this.cameras.length; i++) {
        const canvas = document.getElementById(`canvas-${this.cameras[i]}`);
        
        if (canvas && images[i])
        {
          var img = new Image();
          var that = this;
          img.onload = function() {
            var t1 = Date.now();
            that.unDistort(canvas, this, that.cameras[i]);
            var t2 = Date.now();
            console.log(t2-t1);
          }
          img.src = images[i];
        }
      }
    },
    async getCameraImage() {
      this.capturing = true;
      await delay(100);
      try {
        const images = this.cameras.map(() => null);
        for (let i = 0; i < this.cameras.length; i++) {
          images[i] = await this.$store.dispatch(
            "getBoxSnapshot",
            this.cameras[i]
          );
        }
        this.captureSoundEffect();
        this.capturedImages = images;
        this.barrelDistort();
      } catch (error) {
        console.log(error);
      } finally {
        this.capturing = false;
      }
    },
    barrelDistort() {
      var that = this;
      for (let i = 0; i < this.cameras.length; i++) {
        const canvas = document.getElementById(`canvas-${this.cameras[i]}`);
        const img = new Image();
        
        img.onload = function() {
          that.unDistort(canvas, this, that.cameras[i]);
        }
        
        img.src = this.capturedImages[i];
      }
    },
    save() {
      const values = this.cameras.map(camera => ({ camera }));
      for (let i = 0; i < this.cameras.length; i++) {
        const canvas = document.getElementById(`canvas-${this.cameras[i]}`);
        const dataurl = canvas.toDataURL("image/jpeg");
        values[i].data = dataurl;
      }
      this.$emit("save", values);
    },
    captureSoundEffect() {
      const audio = new Audio(require("@/assets/camera-snap.wav"));
      audio.play();
    }
  },
  watch: {
    cameras: {
      deep: true,
      handler(c) {
        this.cameraImages = c.map(() => null);
        this.capturedImages = c.map(() => null);
      }
    }
  },
  beforeDestroy() {
    window.clearInterval(this.interval);
  }
};
</script>
<style lang="scss" scoped>
canvas {
  width: 100%;
}
</style>
