<template>
  <div class="orders">
    <box-status />
    <v-row v-if="$store.state.user">
      <v-col cols="4">
        <v-card>
          <v-toolbar class="green lighten-5" flat dense>
            <span class="title">
              {{ $t("feet-image") }}
            </span>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click.stop="captureDialog = { show: true, cameras: ['feet'] }"
              v-if="$store.state.user.type === 'user'"
            >
              {{ $t("capture") }}
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <template v-if="images.feet">
              <v-img
                :src="`data:image/jpeg;base64,${images.feet.data}`"
                class="rounded"
                @click="viewZoomDialog(images.feet)"
              />
              <p class="ma-0 mt-3 text-center">{{ images.feet.FileName }}</p>
            </template>
            <p v-else class="ma-0 text-center">
              {{ noImage }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="8">
        <v-card>
          <v-toolbar class="green lighten-5" flat dense>
            <span class="title">
              {{ $t("heel-image") }}
            </span>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click.stop="
                captureDialog = { show: true, cameras: ['heel2', 'heel1'] }
              "
              v-if="$store.state.user.type === 'user' && (($store.state.user.heel1_lens_data && !$store.state.user.heel1_lens_data.disabled) || ($store.state.user.heel2_lens_data && !$store.state.user.heel2_lens_data.disabled))"
            >
              {{ $t("capture") }}
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <p class="ma-0 mb-3 text-subtitle-2">
                  {{ $t("left-heel") }}
                </p>
                <template v-if="images.heel2">
                  <v-img
                    :src="`data:image/jpeg;base64,${images.heel2.data}`"
                    class="rounded"
                    @click="viewZoomDialog(images.heel2)"
                  />
                  <p class="ma-0 mt-3 text-center">
                    {{ images.heel2.FileName }}
                  </p>
                </template>
                <p v-else class="ma-0 text-center">
                  {{ noImage }}
                </p>
              </v-col>
              <v-col cols="6">
                <p class="ma-0 mb-3 text-subtitle-2">
                  {{ $t("right-heel") }}
                </p>
                <template v-if="images.heel1">
                  <v-img
                    :src="`data:image/jpeg;base64,${images.heel1.data}`"
                    class="rounded"
                    @click="viewZoomDialog(images.heel1)"
                  />
                  <p class="ma-0 mt-3 text-center">
                    {{ images.heel1.FileName }}
                  </p>
                </template>
                <p v-else class="ma-0 text-center">
                  {{ noImage }}
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="captureDialog.show"
      transition="dialog-bottom-transition"
      fullscreen
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>
            {{ captureDialogTitle }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="captureDialog = { show: false }; $refs.camcon.activated=0;">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fluid>
          <camera-controller
            ref="camcon"
            v-if="captureDialog.cameras"
            :cameras="captureDialog.cameras"
            :show="captureDialog.show"
            @save="save"
          />
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="zoomDialog.show"
      transition="dialog-bottom-transition"
      fullscreen
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="zoomDialog = { show: false }">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fluid>
          <v-img
            :src="zoomDialog.image"
            class="rounded"
            @click="zoomDialog = { show: false }"
          />
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CameraController from "../components/CameraController.vue";
import BoxStatus from "../components/BoxStatus.vue";
import RecordMixin from "@/mixins/RecordMixin";

export default {
  name: "Capture",
  components: { CameraController, BoxStatus },
  mixins: [RecordMixin],
  data: () => ({
    captureDialog: {
      show: false,
      cameras: null /*array of feet or heel1 or heel2*/
    },
    zoomDialog: {
      show: false,
      image: null
    },
    imagesData: {},
    refreshCounter: 0
  }),
  computed: {
    images() {
      const images = {};
      this.refreshCounter;
      if (this.localRecord) {
        for (const camera of ["feet", "heel1", "heel2"]) {
          if (this.localRecord[`${camera}Image`]) {
            if (
              this.imagesData[camera] &&
              this.localRecord[`${camera}Image`] ===
                this.imagesData[camera].FileName
            )
              images[camera] = this.imagesData[camera];
            else this.downloadImage(camera, this.localRecord[`${camera}Image`]);
          }
        }
      }
      return images;
    },
    captureDialogTitle() {
      if (this.captureDialog.cameras) {
        if (
          !this.captureDialog.cameras
            .map(c => c.startsWith("feet"))
            .includes(false)
        )
          return this.$t("capture-feet-image");
        if (
          !this.captureDialog.cameras
            .map(c => c.startsWith("heel"))
            .includes(false)
        )
          return this.$t("capture-heel-image");
      }
      return this.$t("capture");
    },
    noImage() {
      if (this.$store.state.user.type === "user")
        return this.$t("no-image-user");
      return this.$t("no-image-tech");
    }
  },
  methods: {
    async save(images) {
      if (!images)
      {
        return;
      }
      
      if (
        images.find(i => !!this.localRecord[`${i.camera}Image`]) &&
        !(await this.$confirm(this.$t("image-override-warning"), {
          title: this.$t("warning"),
          buttonTrueText: this.$t("yes"),
          buttonFalseText: this.$t("cancel")
        }))
      )
        return;
      const { PatientID } = this.localRecord;
      for (const i of images) {
        await this.$store.dispatch("upload", {
          camera: i.camera,
          PatientID,
          FileName: `${i.camera}_${new Date().getTime()}.jpg`,
          data: i.data.substring(23) + "===" // remove "data:image/jpeg;base64," from the dataurl and add padding https://gist.github.com/perrygeo/ee7c65bb1541ff6ac770
        });
      }
      this.refreshCounter++;
      this.captureDialog.show = false;
    },
    async downloadImage(camera, FileName) {
      const imageData = await this.$store.dispatch("download", {
        PatientID: this.localRecord.PatientID,
        FileName
      });
      this.imagesData[camera] = imageData;
      this.refreshCounter++;
    },
    viewZoomDialog(image) {
      this.zoomDialog.image='data:image/jpeg;base64,' + image.data;
      this.zoomDialog.show = true;
    }
    
  }
};
</script>
