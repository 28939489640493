export function Fisheye(targetCanvas, image, lens, fov) {
  const vertexShaderSource = `
    #ifdef GL_ES
    precision highp float;
    #endif
    
    attribute vec3 aVertexPosition;
    attribute vec2 aTextureCoord;
    varying vec3 vPosition;
    varying vec2 vTextureCoord;
    
    void main(void) {
      vPosition = aVertexPosition;
      vTextureCoord = aTextureCoord;
      gl_Position = vec4(vPosition,1.0);
    }
  `;

  const fragmentShaderSource = `
    #ifdef GL_ES
    precision highp float;
    #endif
    
    uniform vec3 uLensS;
    uniform vec2 uLensF;
    uniform vec2 uLensF2;
    uniform vec2 uLensF3;
    uniform vec2 uLensT;
    uniform vec2 uLensP;
    uniform vec2 uLensQ;
    uniform vec2 uFov;
    uniform float uRot;
    uniform vec2 uRotCenter;
    uniform vec2 uShift;
    uniform vec4 uWin;
    uniform vec4 uBlack;
    uniform sampler2D uSampler;
    varying vec3 vPosition;
    varying vec2 vTextureCoord;
    
    vec2 GLCoord2TextureCoord(vec2 glCoord) {
      return glCoord  * vec2(1.0, -1.0)/ 2.0 + vec2(0.5, 0.5);
    }
    
    mat2 rotate2d(float a) {
      return mat2(cos(a),-sin(a), sin(a),cos(a));
    }
    
    void main(void) {
      float scale = uLensS.z;
      float Fx = uLensF.x;
      float Fy = uLensF.y;
      float F2x = uLensF2.x;
      float F2y = uLensF2.y;
      float F3x = uLensF3.x;
      float F3y = uLensF3.y;
      float Tx = uLensT.x;
      float Ty = uLensT.y;
      float Px = uLensP.x;
      float Py = uLensP.y;
      float Qx = uLensQ.x;
      float Qy = uLensQ.y;
      
      vec3 vPos = vPosition;
      
      vPos.x = ((vPosition.x+1.0)/2.0 * (uWin.z-uWin.x) + uWin.x)*2.0-1.0;
      vPos.y = ((vPosition.y+1.0)/2.0 * (uWin.y-uWin.w) - uWin.y + 1.0)*2.0-1.0;
      
      vPos.xy = (vPos.xy+1.0)/2.0;
      vPos.xy = vPos.xy - uRotCenter;
      vPos.xy = vPos.xy * rotate2d(uRot);
      vPos.xy = vPos.xy + uRotCenter;
      vPos.xy = vPos.xy*2.0 - 1.0;
      
      vec2 vPosOld = vPos.xy;
      
      vPos.x = vPos.x - vPosOld.y*Px;
      vPos.y = vPos.y - vPosOld.x*Py;
      
      vPos.x = vPos.x - vPosOld.y*vPosOld.x*Tx;
      vPos.y = vPos.y - vPosOld.x*vPosOld.y*Ty;
      
      vPos.x = vPos.x - vPosOld.x*vPosOld.x*Qx;
      vPos.y = vPos.y - vPosOld.y*vPosOld.y*Qy;
      
      vec2 vMapping = vPos.xy;
      vMapping = vMapping + uShift;
      vMapping.x = vMapping.x - ((pow(vPos.y, 2.0)/scale)*vPos.x/scale)*Fx;
      vMapping.y = vMapping.y - ((pow(vPos.x, 2.0)/scale)*vPos.y/scale)*Fy;
      vMapping.x = vMapping.x - ((pow(vPos.y, 2.0)/scale)*vPos.x/scale)*F2x*(abs(vPos.y) - F3x);
      vMapping.y = vMapping.y - ((pow(vPos.x, 2.0)/scale)*vPos.y/scale)*F2y*(abs(vPos.x) - F3y);
      
//       vMapping.x = vMapping.x - ((sin(pow(abs(vPos.y), 2.0)*3.1415/2.0)/scale)*vPos.x/scale)*F2x;
//       vMapping.y = vMapping.y - ((sin(pow(abs(vPos.x), 2.0)*3.1415/2.0)/scale)*vPos.y/scale)*F2y;
//       vMapping.x = vMapping.x - ((pow(abs(vPos.y), 4.0)/scale)*vPos.x/scale)*F3x;
//       vMapping.y = vMapping.y - ((pow(abs(vPos.x), 4.0)/scale)*vPos.y/scale)*F3y;
      vMapping = vMapping * uLensS.xy;
      vMapping = GLCoord2TextureCoord(vMapping/scale);
      
      vec4 texture = texture2D(uSampler, vMapping);
      
      if (vMapping.x > 0.999 || vMapping.x < 0.001 || vMapping.y > 0.999 || vMapping.y < 0.001) {
        texture = vec4(0.0, 0.0, 0.0, 1.0);
      }

      if ((vPosition.x+1.0)/2.0 > uBlack.z || (vPosition.x+1.0)/2.0 < uBlack.x || (vPosition.y+1.0)/2.0 > uBlack.w || (vPosition.y+1.0)/2.0 < uBlack.y) {
        texture = vec4(0.0, 0.0, 0.0, 1.0);
      }
      
      gl_FragColor = texture;
      
    }
  `;
  
  var canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;
  canvas.style.visibility = 'hidden';
  document.body.appendChild(canvas);
  canvas.style.visibility = 'hidden';
  
  var model = {
    vertex :[
      -1.0, -1.0, 0.0,
       1.0, -1.0, 0.0,
       1.0,  1.0, 0.0,
      -1.0,  1.0, 0.0
    ],
    indices :[
      0, 1, 2,
      0, 2, 3,
      2, 1, 0,
      3, 2, 0
    ],
    textureCoords : [
      0.0, 0.0,
      1.0, 0.0,
      1.0, 1.0,
      0.0, 1.0
    ]
  };

  var vertexBuffer, indexBuffer, textureBuffer;

  function getGLContext(canvas) {
    if(canvas == null) {
      throw new Error("there is no canvas on this page");
    }
    
    var names = ["webgl", "experimental-webgl", "webkit-3d", "moz-webgl"];
    for (var i = 0; i < names.length; ++i) {
      var gl;
      try {
        gl = canvas.getContext(names[i], { preserveDrawingBuffer: true });
      } catch(e) {
        continue;
      }
      if (gl) return gl;
    }
    
    throw new Error("WebGL is not supported!");
  }
  
  function compileShader(gl, vertexSrc, fragmentSrc) {
    var vertexShader = gl.createShader(gl.VERTEX_SHADER);
    gl.shaderSource(vertexShader, vertexSrc);
    gl.compileShader(vertexShader);
    
    _checkCompile(vertexShader);
    
    var fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    gl.shaderSource(fragmentShader, fragmentSrc);
    gl.compileShader(fragmentShader);
    
    _checkCompile(fragmentShader);
    
    var program = gl.createProgram();
    
    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    
    gl.linkProgram(program);
    
    return program;
    
    function _checkCompile(shader){
      if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        throw new Error(gl.getShaderInfoLog(shader));
      }
    }
  }
  
  function createBuffers() {
    vertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(model.vertex), gl.STATIC_DRAW);
    gl.bindBuffer(gl.ARRAY_BUFFER, null);

    indexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
    gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(model.indices), gl.STATIC_DRAW);
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);

    textureBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, textureBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(model.textureCoords), gl.STATIC_DRAW);
    gl.bindBuffer(gl.ARRAY_BUFFER, null);

  }

  var gl = getGLContext(canvas);
  
  var program = compileShader(gl, vertexShaderSource, fragmentShaderSource)
  gl.useProgram(program);
  
  var aVertexPosition = gl.getAttribLocation(program, "aVertexPosition");
  var aTextureCoord = gl.getAttribLocation(program, "aTextureCoord");
  var uSampler = gl.getUniformLocation(program, "uSampler");
  var uLensS = gl.getUniformLocation(program, "uLensS");
  var uLensF = gl.getUniformLocation(program, "uLensF");
  var uLensF2 = gl.getUniformLocation(program, "uLensF2");
  var uLensF3 = gl.getUniformLocation(program, "uLensF3");
  var uLensT = gl.getUniformLocation(program, "uLensT");
  var uLensP = gl.getUniformLocation(program, "uLensP");
  var uLensQ = gl.getUniformLocation(program, "uLensQ");
  var uFov = gl.getUniformLocation(program, "uFov");
  var uRot = gl.getUniformLocation(program, "uRot");
  var uRotCenter = gl.getUniformLocation(program, "uRotCenter");
  var uShift = gl.getUniformLocation(program, "uShift");
  var uWin = gl.getUniformLocation(program, "uWin");
  var uBlack = gl.getUniformLocation(program, "uBlack");
  
  createBuffers();

  function loadImage(gl, img) {
    texture = gl.createTexture();

    gl.bindTexture(gl.TEXTURE_2D, texture);

    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, img);

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR); //gl.NEAREST is also allowed, instead of gl.LINEAR, as neither mipmap.
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE); //Prevents s-coordinate wrapping (repeating).
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE); //Prevents t-coordinate wrapping (repeating).
    //gl.generateMipmap(gl.TEXTURE_2D);
    gl.bindTexture(gl.TEXTURE_2D, null);

    return texture;
  }

  /*
  function run(animate, callback){
    var f = window.requestAnimationFrame || window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;

    // ugh
    if(animate === true){
      if(f){
        f(on);
      } else {
        throw new Error("do not support 'requestAnimationFram'");
      }
    } else {
      f(on);
    }

    var current = null;
    function on(t){
      if(!current) current = t;
      var dt = t - current;
      current = t;
      options.runner(dt);
      if (callback) callback();
      if (animate === true) f(on);
    }
  }
  */
  
  var texture = loadImage(gl, image);
  
  gl.clearColor(0.0, 0.0, 0.0, 1.0);
  gl.enable(gl.DEPTH_TEST);

  gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

  gl.enableVertexAttribArray(aVertexPosition);

  gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
  gl.vertexAttribPointer(aVertexPosition, 3, gl.FLOAT, false, 0, 0);

  gl.enableVertexAttribArray(aTextureCoord);

  gl.bindBuffer(gl.ARRAY_BUFFER, textureBuffer);
  gl.vertexAttribPointer(aTextureCoord, 2, gl.FLOAT, false, 0, 0);

  gl.activeTexture(gl.TEXTURE0);
  gl.bindTexture(gl.TEXTURE_2D, texture);
  gl.uniform1i(uSampler, 0);

  if (lens.Tx === undefined)
  {
    lens.Tx = 0.0;
    lens.Ty = 0.0;
  }
  
  if (lens.Px === undefined)
  {
    lens.Px = 0.0;
    lens.Py = 0.0;
  }

  if (lens.Qx === undefined)
  {
    lens.Qx = 0.0;
    lens.Qy = 0.0;
  }
  
  gl.uniform3fv(uLensS, [lens.a, lens.b, lens.scale]);
  gl.uniform2fv(uLensF, [lens.Fx, lens.Fy]);
  gl.uniform2fv(uLensF2, [lens.F2x, lens.F2y]);
  gl.uniform2fv(uLensF3, [lens.F3x, lens.F3y]);
  gl.uniform2fv(uLensT, [lens.Tx, lens.Ty]);
  gl.uniform2fv(uLensP, [lens.Px, lens.Py]);
  gl.uniform2fv(uLensQ, [lens.Qx, lens.Qy]);
  gl.uniform2fv(uFov, [fov.x, fov.y]);
  gl.uniform1f(uRot, lens.rotation);
  gl.uniform2fv(uRotCenter, [lens.rotationX, lens.rotationY]);
  gl.uniform2fv(uShift, [lens.shiftX, lens.shiftY]);
  gl.uniform4fv(uWin, [lens.wx1, lens.wy1, lens.wx2, lens.wy2]);
  gl.uniform4fv(uBlack, [lens.bx1, lens.by1, lens.bx2, lens.by2]);
  
  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
  gl.drawElements(gl.TRIANGLES, model.indices.length, gl.UNSIGNED_SHORT, 0);
  
  targetCanvas.width = image.width;
  targetCanvas.height = image.height;
  var targetCtx = targetCanvas.getContext('2d');
  targetCtx.drawImage(canvas, 0, 0);
  
  canvas.remove();
}
